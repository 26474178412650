<template>
  <div class="bg-pink">
    <div class="q-pa-md absolute-center">
      <q-spinner color="pink" size="10em" :thickness="10" />
    </div>
  </div>
</template>

<script>
import { setLoginRoute } from "@/modules/partner/storage";
import checkAuth from "@/router/middleware/checkAuth";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";

export default {
  name: "ConfigRedirectComponent",

  setup() {
    const userLogged = ref(false),
      route = useRoute(),
      router = useRouter();

    onMounted(() => {
      setTimeout(async () => {
        userLogged.value = await checkAuth();

        const parentSlug = route.params.slug,
          pathToAccess = `/` + route.fullPath.split("/").pop(),
          pathWithoutParam = pathToAccess.split("#").shift();

        if (userLogged.value) {
          if (pathToAccess.includes("missions")) {
            const slug = pathToAccess.split("#").pop();

            localStorage.setItem(
              "showFeedBack",
              JSON.stringify({
                tab: pathWithoutParam,
                slug: slug,
              })
            );

            router.push({ name: `missions` });
            return;
          }
          if (pathToAccess.includes("mission")) {
            const slug = pathToAccess.split("#").pop();
            router.push({ name: `view-mission`, params: { slug } });
            return;
          }

          if (pathToAccess.includes("#")) setLoginRoute(pathToAccess);
          router.push({ path: pathWithoutParam });
        } else {
          setLoginRoute(pathToAccess);

          if (pathToAccess.includes("missions")) {
            const slug = pathToAccess.split("#").pop();

            localStorage.setItem(
              "showFeedBack",
              JSON.stringify({
                tab: pathWithoutParam,
                slug: slug,
              })
            );
          }

          router.push({ name: "login", params: { slug: parentSlug } });
        }
      }, 2500);
    });
  },
};
</script>
